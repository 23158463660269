import React, { Fragment, useState } from 'react'
import * as yup from 'yup'
import fetch from 'cross-fetch'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Form from 'components/Form'
import CalendarIcon from 'components/icons/Calendar'
import Container from 'components/Container'
import newPatientForm from 'static/documents/new_patient_form.pdf'
import IconContainer from 'components/icons/Container'
import Field from 'components/Form/Field'
import composeHandlers from 'utils/composeHandlers'
import { Button, FormControl, TextField } from '@material-ui/core'

const FormInput = ({ name, onChange, onBlur, formatValue, ...props }) => (
  <Field
    name={name}
    render={({
      onBlur: fieldOnBlur,
      onChange: fieldOnChange,
      renderError,
      setValue,
      valid,
      form,
      ...field
    }) => (
      <Fragment>
        <TextField
          {...props}
          {...field}
          onBlur={composeHandlers(fieldOnBlur, onBlur)}
          onChange={e => {
            onChange && onChange(e)
            formatValue
              ? setValue(formatValue(e.target.value))
              : fieldOnChange(e)
          }}
          error={!valid}
        />
        {renderError()}
      </Fragment>
    )}
  />
)

const Description = props => (
  <p className="text-base leading-snug my-4" {...props} />
)

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Email must be a valid email address')
    .required("Email can't be blank"),
  first_name: yup.string().required("First name can't be blank"),
  last_name: yup.string().required("Last name can't be blank"),
  phone: yup
    .number()
    .required("Phone number can't be blank")
    .positive()
})

const Appointments = () => {
  const [sent, setSent] = useState(false)

  return (
    <Layout>
      <Helmet title="Oregon City Smiles - Appointments" />
      <Container>
        <h1 className="text-3xl text-center">
          <IconContainer color="purple">
            <CalendarIcon color="white" size="2rem" />
          </IconContainer>
          Request an appointment
        </h1>
        <Description>
          We value your time. You will be pleased to find that we will generally
          see you right at the appointed time. We will make every effort to
          complete your treatment in as few visits as possible. In order for us
          to be efficient with the time that we have scheduled for you, we would
          ask that you also be prompt and always give 24 hours notice if you are
          unable to keep an appointment so that other patients on our waiting
          list may be served.
        </Description>

        <Description>
          Our office hours are 8:00 AM to 5:00 PM on Monday through Thursday,
          8:00 AM to 1:00 PM on Friday.
        </Description>

        <Description>
          To save yourself some time on the first appointment, you can fill out
          our new patient registration form at home. Download and print our
          patient forms here. If you have any questions, please feel free to
          call our office.
        </Description>

        <Description>
          <a
            className="block"
            href={newPatientForm}
            rel="noopener noreferrer"
            target="_blank"
          >
            New Patient Registration Form
          </a>
        </Description>

        <Description>
          Enter your contact information and preferred appointment date, then
          click "Send Info." Our staff will contact you soon to schedule an
          appointment.
        </Description>
      </Container>

      <Container>
        <div className="w-full lg:w-9/12 bg-white rounded p-4 mx-auto my-8 shadow-2xl">
          {sent ? (
            <Description>Successfully requested</Description>
          ) : (
            <Form
              validationSchema={schema}
              onSubmit={params =>
                fetch(
                  'https://ins429.dynu.net:60429/oregon-city-smiles/request_appointment',
                  {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(params)
                  }
                ).then(() => {
                  setSent(true)
                })
              }
            >
              <FormControl fullWidth margin="dense">
                <FormInput name="first_name" label="First Name" />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <FormInput name="last_name" label="Last Name" />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <FormInput name="phone" label="Phone" />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <FormInput name="email" label="Email" />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <FormInput name="comments" label="Comments" multiline />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <div className="flex justify-end">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="bg-open-blue"
                  >
                    Request Appointment
                  </Button>
                </div>
              </FormControl>
            </Form>
          )}
        </div>
      </Container>
    </Layout>
  )
}

export default Appointments
